import axios from 'axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { successMessage, failureMessage } from '../Notifications/actions';
import config from '../../config.json';

import {
	createPaymentIntentCompleted,
	createPaymentIntentFailed
} from './actions.js';
import { CREATE_PAYMENT_INTENT } from './actionTypes.js';

export const createPaymentIntentEpic = (action$) =>
	action$.pipe(
		ofType(CREATE_PAYMENT_INTENT),
		mergeMap(async (action) => {
			const body = action.payload;
			// const { username, password } = action.payload;
			// const user = await Auth.signIn(username, password);
			// const session = await Auth.currentSession();
			const uri = config.api[process.env.REACT_APP_STAGE] || config.api.prod;

			console.log('WHATS MY URI', { config, uri });

			const response = await axios.post(
				`${uri}/api/v1/payments/create-payment-intent`,
				body,
				{
					headers: {
						'Content-Type': 'application/json'
					}
				}
			);

			console.log('INTENT RESPONSE', response);

			// Create PaymentIntent as soon as the page loads
			// fetch('http://localhost:4000/api/v1/payments/create-payment-intent', {
			// 	method: 'POST',
			// 	headers: { 'Content-Type': 'application/json' },
			// 	body: JSON.stringify({ items: [{ id: 'xl-tshirt' }] })
			// })
			// 	.then((res) => res.json())
			// 	.then((data) => setClientSecret(data.clientSecret));

			return response;
		}),
		switchMap((res) => [
			createPaymentIntentCompleted(res.data),
			successMessage('Payment Intent Created Successfully')
		]),
		catchError((error, source) =>
			merge(
				of(createPaymentIntentFailed(), failureMessage(error.message)),
				source
			)
		)
	);
