import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const styles = (theme) => ({
	counterDetails: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		'& p': {
			margin: '0'
		}
	},
	root: {
		display: 'flex',
		flexDirection: 'row'
	},
	totalsDetails: {
		display: 'flex',
		width: '100%',
		height: '10px',
		justifyContent: 'space-between'
	}
});

const CartItems = (props) => {
	const { cart, classes } = props;
	const { counterDetails, totalsDetails } = classes;

	const groupCartItems = () => {
		const groupedItems = {};

		cart.map((cartItem) => {
			if (groupedItems[cartItem.id]) {
				groupedItems[cartItem.id].count++;
			} else {
				groupedItems[cartItem.id] = Object.assign({}, cartItem);
				groupedItems[cartItem.id].count = 1;
			}
		});

		const items = Object.values(groupedItems);
		if (!items.length) {
			return (
				<ListItem>
					<ListItemText primary="You do not have any items in your cart." />
				</ListItem>
			);
		}

		return items.map((cartItem, idx) => (
			<ListItem key={idx}>
				<ListItemAvatar>
					<Avatar src={cartItem.images[0]}>
						<CheckCircleIcon />
					</Avatar>
				</ListItemAvatar>
				<ListItemText
					primary={cartItem.title}
					secondary={cartItem.description}
				/>
				<div className={counterDetails}>
					<Typography sx={{ mt: 4, mb: 2 }} variant="caption" component="p">
						{cartItem.price} x {cartItem.count}
					</Typography>
					<Button size="small" variant="outlined">
						remove
					</Button>
				</div>
			</ListItem>
		));
	};

	const calculateTotals = () => {
		return (
			<Fragment>
				<ListItem>
					<div className={totalsDetails}>
						<div></div>
						<Typography sx={{ mt: 4, mb: 2 }} variant="caption" component="p">
							Tax: $18.20
						</Typography>
					</div>
				</ListItem>
				<ListItem>
					<div className={totalsDetails}>
						<div></div>
						<Typography sx={{ mt: 4, mb: 2 }} variant="caption" component="p">
							Shipping: $18.20
						</Typography>
					</div>
				</ListItem>
				<ListItem>
					<hr />
				</ListItem>
				<ListItem>
					<div className={totalsDetails}>
						<div></div>
						<Typography sx={{ mt: 4, mb: 2 }} variant="subtitle2" component="p">
							Total: $18.20
						</Typography>
					</div>
				</ListItem>
			</Fragment>
		);
	};

	return (
		<List dense={true}>
			{groupCartItems()}
			{calculateTotals()}
		</List>
	);
};

CartItems.propTypes = {
	cart: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	classes: PropTypes.object,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		cart: state.cartReducer.get('cart')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		// toggleShoppingCart: () => {
		// dispatch(cartActions.toggleCartModal());
		// }
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(CartItems));
