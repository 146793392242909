import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { validateOrderFormInputs } from '../../utilities/validateOrderFormInputs';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import config from '../../config.json';
import CartItems from './CartItems';
import OrderFormModal from '../Order/OrderFormModal';

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const styles = (theme) => ({
	buyNow: {
		display: 'flex',
		justifyContent: 'right',
		margin: '80px 80px 80px 0px'
	},
	inputField: {
		marginTop: '6px'
	},
	orderInfo: {
		display: 'flex',
		flexDirection: 'column',
		marginTop: '40px',
		width: '580px'
	},
	root: {
		display: 'flex',
		flexDirection: 'row'
	}
});

const Cart = (props) => {
	const { cart, classes } = props;
	const { buyNow, cartItems, inputField, orderInfo, root } = classes;

	const [value, setValue] = useState(null);
	const [orderFormModalIsOpen, setOrderFormModalIsOpen] = useState(false);
	const [paymentIntent, setPaymentIntent] = useState(null);
	const [orderFormValues, setOrderFormValues] = useState({});
	const [buyNowInactive, setBuyNowInactive] = useState(true);
	const [inputErrors, setInputErrors] = useState({});

	const validateInputs = () => {
		const orderFormIsValid = validateOrderFormInputs(
			orderFormValues,
			inputErrors,
			setInputErrors
		);

		if (orderFormIsValid) {
			setBuyNowInactive(false);
		} else {
			setBuyNowInactive(true);
		}
	};

	const checkForErrors = (key) => {
		return inputErrors[key] !== undefined ? inputErrors[key] : '';
	};

	const handleCloseOrderFormModal = () => {
		setOrderFormModalIsOpen(!orderFormModalIsOpen);
	};

	const handleBuyNow = () => {
		// console.log('PURCHASE', {
		// 	orderFormValues,
		// 	cart
		// });

		const paymentIntent = {
			items: [],
			customer: orderFormValues
		};

		cart.map((item) => {
			paymentIntent.items.push({ id: item.id });
		});

		console.log('paymentIntent', paymentIntent);

		setPaymentIntent(paymentIntent);
		handleCloseOrderFormModal();
	};

	useEffect(() => {
		if (Object.keys(orderFormValues).length > 0) {
			validateInputs();
		}
	}, [orderFormValues, setOrderFormValues]);

	const updateOrderForm = (key, value) => {
		const orderFormValuesClone = Object.assign({}, orderFormValues);

		if (key === 'address') {
			const googleApiLocation = value.value;
			const terms = googleApiLocation.terms;
			const address = Object.values(terms);

			orderFormValuesClone.addressOne = `${address[0].value} ${address[1].value}`;
			orderFormValuesClone.addressCity = address[2].value;
			orderFormValuesClone.addressState = address[3].value;
			orderFormValuesClone.addressCountry = address[4].value;

			setOrderFormValues(orderFormValuesClone);
			setValue(value);
		} else {
			orderFormValuesClone[key] = value;
			setOrderFormValues(orderFormValuesClone);
		}
	};

	return (
		<Container maxWidth={false}>
			<Container className={root} maxWidth={false}>
				<Container className={cartItems} maxWidth={false}>
					<Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
						Your Cart
					</Typography>
					<CartItems />
				</Container>
				<Container className={orderInfo} maxWidth={false}>
					<Container className={orderInfo} maxWidth={false}>
						<p>Address Search</p>
						<GooglePlacesAutocomplete
							apiKey={config.GOOGLE_PLACES_API_KEY}
							selectProps={{
								value,
								onChange: (e) => updateOrderForm('address', e)
							}}
						/>
					</Container>
					<Container className={orderInfo} maxWidth={false}>
						<TextField
							error={!!checkForErrors('firstName')}
							value={orderFormValues.firstName || ''}
							label="First Name"
							className={inputField}
							variant="outlined"
							helperText={checkForErrors('firstName')}
							onChange={(e) => updateOrderForm('firstName', e.target.value)}
						/>
						<TextField
							error={!!checkForErrors('lastName')}
							value={orderFormValues.lastName || ''}
							label="Last Name"
							className={inputField}
							variant="outlined"
							helperText={checkForErrors('lastName')}
							onChange={(e) => updateOrderForm('lastName', e.target.value)}
						/>
						<TextField
							error={!!checkForErrors('email')}
							value={orderFormValues.email || ''}
							label="Email"
							className={inputField}
							variant="outlined"
							helperText={checkForErrors('email')}
							onChange={(e) => updateOrderForm('email', e.target.value)}
						/>
						<TextField
							error={!!checkForErrors('phone')}
							label="Phone"
							className={inputField}
							variant="outlined"
							helperText={checkForErrors('phone')}
							onChange={(e) => updateOrderForm('phone', e.target.value)}
						/>
						<TextField
							error={!!checkForErrors('addressOne')}
							value={orderFormValues.addressOne || ''}
							label="Address 1"
							className={inputField}
							variant="outlined"
							helperText={checkForErrors('addressOne')}
							onChange={(e) => updateOrderForm('addressOne', e.target.value)}
						/>
						<TextField
							label="Address 2"
							value={orderFormValues.addressTwo || ''}
							className={inputField}
							variant="outlined"
							onChange={(e) => updateOrderForm('addressTwo', e.target.value)}
						/>
						<TextField
							error={!!checkForErrors('addressCity')}
							value={orderFormValues.addressCity || ''}
							label="City"
							className={inputField}
							variant="outlined"
							helperText={checkForErrors('addressCity')}
							onChange={(e) => updateOrderForm('addressCity', e.target.value)}
						/>
						<TextField
							error={!!checkForErrors('addressState')}
							value={orderFormValues.addressState || ''}
							label="State"
							className={inputField}
							variant="outlined"
							helperText={checkForErrors('addressState')}
							onChange={(e) => updateOrderForm('addressState', e.target.value)}
						/>
						<TextField
							error={!!checkForErrors('addressCountry')}
							value={orderFormValues.addressCountry || ''}
							label="Country"
							className={inputField}
							variant="outlined"
							helperText={checkForErrors('addressCountry')}
							onChange={(e) =>
								updateOrderForm('addressCountry', e.target.value)
							}
						/>
						<TextField
							error={!!checkForErrors('addressZip')}
							value={orderFormValues.addressZip || ''}
							label="Zip"
							className={inputField}
							variant="outlined"
							helperText={checkForErrors('addressZip')}
							onChange={(e) => updateOrderForm('addressZip', e.target.value)}
						/>
					</Container>
				</Container>
			</Container>
			<Container className={buyNow} maxWidth={false}>
				<Button
					disabled={buyNowInactive}
					color="primary"
					variant="contained"
					onClick={handleBuyNow}
				>
					Buy Now
				</Button>
			</Container>
			<OrderFormModal
				open={orderFormModalIsOpen}
				handleClose={handleCloseOrderFormModal}
				paymentIntent={paymentIntent}
			/>
		</Container>
	);
};

Cart.propTypes = {
	cart: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	classes: PropTypes.object,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		cart: state.cartReducer.get('cart')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		// toggleShoppingCart: () => {
		// dispatch(cartActions.toggleCartModal());
		// }
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(Cart));
