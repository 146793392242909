import React from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import Container from '@mui/material/Container';

const styles = (theme) => ({
	pageContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		flexBasis: '100%',
		width: '100%',
		alignItems: 'center',
		color: 'white',
		'& div': {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			marginBottom: '25px'
		},
		background:
			'linear-gradient(114deg, rgba(221,81,57,1) 0%, rgba(135,11,35,1) 100%)'
	}
});

// TODO: This should show the confirmed information about the order.

const OrderCompleted = (props) => {
	const { classes } = props;
	const { pageContainer } = classes;

	const params = new URLSearchParams(window.location.search);
	const paymentIntent = params.get('payment_intent');

	console.log('PARAMS', window.location);
	console.log('PARAMS', params.get('payment_intent'));
	// TODO: This is an example URL, pull the 'payment_intent'
	// http://localhost:3000/order-completed?payment_intent=pi_3LlIM4ILiacwg2bn02zjpupI&payment_intent_client_secret=pi_3LlIM4ILiacwg2bn02zjpupI_secret_nbElBQFWlqiDkPff0yAdJJI70&redirect_status=succeeded

	return (
		<Container className={pageContainer} maxWidth={false}>
			<h2>Order Placed Successfully</h2>
			<p>Order Number: #{paymentIntent}</p>
		</Container>
	);
};

OrderCompleted.propTypes = {
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(OrderCompleted);
