import React from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useHistory } from 'react-router';

const styles = (theme) => ({
	pageContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		flexBasis: '100%',
		width: '100%',
		alignItems: 'center',
		color: 'white',
		'& div': {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			marginBottom: '25px'
		},
		background:
			'linear-gradient(114deg, rgba(221,81,57,1) 0%, rgba(135,11,35,1) 100%)'
	},
	videoContainer: {
		display: 'flex',
		maxWidth: '720px',
		maxHeight: '480px'
	}
});

const Landing = (props) => {
	const {
		classes
		// t
	} = props;
	const { pageContainer, videoContainer } = classes;

	const history = useHistory();

	const handleOrderNow = () => {
		history.push('/order');
	};

	return (
		<Container className={pageContainer} maxWidth={false}>
			<div>
				<Typography variant="h2" component="h2">
					Pgroomer
				</Typography>
				<Typography variant="body2" component="p">
					The all encompassing massage/grooming tool for your fury friends.
				</Typography>
				<Typography variant="body2" component="p">
					Check out the product on indiegogo.
				</Typography>
				<Button variant="contained" onClick={handleOrderNow}>
					ORDER NOW
				</Button>
			</div>
			<Paper elevation={3}>
				<Container className={videoContainer} maxWidth={false}>
					<video className={videoContainer} controls>
						<source
							src="https://assets.pgroomer.com/videos/ProfessionalGroomer4_sm.mp4"
							type="video/mp4"
						/>
					</video>
				</Container>
			</Paper>
		</Container>
	);
};

Landing.propTypes = {
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(Landing);
