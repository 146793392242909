import {
	CREATE_PAYMENT_INTENT,
	CREATE_PAYMENT_INTENT_COMPLETED,
	CREATE_PAYMENT_INTENT_FAILED
} from './actionTypes';

export const createPaymentIntent = (payload) => ({
	type: CREATE_PAYMENT_INTENT,
	payload
});
export const createPaymentIntentCompleted = (payload) => ({
	type: CREATE_PAYMENT_INTENT_COMPLETED,
	payload
});

export const createPaymentIntentFailed = (payload) => ({
	type: CREATE_PAYMENT_INTENT_FAILED,
	payload
});
