import React from 'react';
import { Provider } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Router from './components/Router';
import store from './store';
import './i18n';

const stripePromise = loadStripe(
	'pk_test_51LBpBrILiacwg2bnuckU2FsUV35IjI7XbqqrUMueRZ2vELc3ddd4TjM9k1EQx7dJ8Mxvki8cCyHS53y1ah9qVj6n00oLnsiHu4'
);

const App = () => {
	// const options = {
	// 	// passing the client secret obtained from the server
	// 	clientSecret: ''
	// };

	return (
		<Elements
			stripe={stripePromise}
			// options={options}
		>
			<Provider store={store}>
				<Router />
			</Provider>
		</Elements>
	);
};

export default App;
