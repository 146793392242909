import React from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import OrderForm from './OrderForm';

import Container from '@mui/material/Container';

const styles = (theme) => ({
	pageContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		flexBasis: '100%',
		width: '100%',
		alignItems: 'center',
		color: 'white',
		'& div': {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			marginBottom: '25px'
		},
		background:
			'linear-gradient(114deg, rgba(221,81,57,1) 0%, rgba(135,11,35,1) 100%)'
	}
});

const Splash = (props) => {
	const { classes } = props;
	const { pageContainer } = classes;

	return (
		<Container className={pageContainer} maxWidth={false}>
			<p>Order Your Tool Here.</p>
			<OrderForm />
		</Container>
	);
};

Splash.propTypes = {
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(Splash);
