const requiredFields = [
	'addressOne',
	'addressCity',
	'addressState',
	'addressCountry',
	'addressZip',
	'firstName',
	'lastName',
	'email',
	'phone'
];

const fieldTranslations = {
	addressOne: 'Address 1',
	addressCity: 'City',
	addressState: 'State',
	addressCountry: 'Country',
	addressZip: 'Zip',
	firstName: 'First Name',
	lastName: 'Last Name',
	email: 'Email',
	phone: 'Phone'
};

const fieldTranslator = (field) => {
	return fieldTranslations[field];
};

export const validateOrderFormInputs = (inputs, inputError, setInputError) => {
	const fieldsPresent = Object.keys(inputs);
	const cloneErrors = Object.assign({}, inputError);

	requiredFields.forEach(function (field) {
		if (fieldsPresent.includes(field)) {
			const fieldValue = inputs[field];
			if (fieldValue === '' || fieldValue === null) {
				cloneErrors[field] = `${fieldTranslator(field)} is required`;
			} else {
				delete cloneErrors[field];
			}
		} else {
			cloneErrors[field] = `${fieldTranslator(field)} is required`;
		}
	});

	setInputError(cloneErrors);

	return !(Object.keys(cloneErrors).length > 0);
};
