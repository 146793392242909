import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import OrderForm from './OrderForm';

import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4
};

const styles = (theme) => ({
	root: {}
});

const BasicModal = (props) => {
	const { open, handleClose, paymentIntent } = props;

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				<OrderForm paymentIntent={paymentIntent} />
				{/* <Typography id="modal-modal-title" variant="h6" component="h2">
					Text in a modal
				</Typography>
				<Typography id="modal-modal-description" sx={{ mt: 2 }}>
					Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
				</Typography> */}
			</Box>
		</Modal>
	);
};

BasicModal.propTypes = {
	classes: PropTypes.object,
	handleClose: PropTypes.func,
	open: PropTypes.bool,
	paymentIntent: PropTypes.object,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		// isLoading: state.orderReducer.get('paymentIntent').get('isLoading'),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		createPaymentIntent: (payload) => {
			// dispatch(orderActions.createPaymentIntent(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(BasicModal));
