import axios from 'axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { successMessage, failureMessage } from '../Notifications/actions';
import config from '../../config.json';

import { getProductsCompleted, getProductsFailed } from './actions.js';
import { GET_PRODUCTS } from './actionTypes.js';

export const getProductsEpic = (action$) =>
	action$.pipe(
		ofType(GET_PRODUCTS),
		mergeMap(async (action) => {
			const uri = config.api[process.env.REACT_APP_STAGE] || config.api.prod;
			const response = await axios.get(`${uri}/api/v1/products`, {
				headers: {
					'Content-Type': 'application/json'
				}
			});

			// Create PaymentIntent as soon as the page loads
			// fetch('http://localhost:4000/api/v1/payments/create-payment-intent', {
			// 	method: 'POST',
			// 	headers: { 'Content-Type': 'application/json' },
			// 	body: JSON.stringify({ items: [{ id: 'xl-tshirt' }] })
			// })
			// 	.then((res) => res.json())
			// 	.then((data) => setClientSecret(data.clientSecret));

			// const response = {
			// 	data: {
			// 		count: 2,
			// 		results: [
			// 			{
			// 				id: '80312ed8-e8b6-4b75-aa07-5b8956647932',
			// 				title: 'The Standard',
			// 				description: 'This is the Standard description',
			// 				images: [
			// 					'https://assets.pgroomer.com/photos/TheStandardProduct.jpg'
			// 				],
			// 				price: 60.0
			// 			},
			// 			{
			// 				id: '53f0abd1-4111-484d-984c-94cb0bf5e73a',
			// 				title: 'The Professional',
			// 				description: 'This is the full professional set description',
			// 				images: [
			// 					'https://assets.pgroomer.com/photos/TheProfessionalProduct.jpg'
			// 				],
			// 				price: 90.0
			// 			}
			// 		]
			// 	}
			// };

			return response;
		}),
		switchMap((res) => [
			getProductsCompleted(res.data),
			successMessage('Returned Products Successfully')
		]),
		catchError((error, source) =>
			merge(of(getProductsFailed(), failureMessage(error.message)), source)
		)
	);
