import { fromJS } from 'immutable';
import {
	GET_PRODUCTS,
	GET_PRODUCTS_COMPLETED,
	GET_PRODUCTS_FAILED
} from './actionTypes';

export const initialState = fromJS({
	products: {
		isLoading: false,
		hasBeenLoaded: false,
		products: {},
		error: null
	}
});

export const productsReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_PRODUCTS:
			return state.setIn(['products', 'isLoading'], true);

		case GET_PRODUCTS_COMPLETED:
			return state
				.setIn(['products', 'isLoading'], false)
				.setIn(['products', 'products'], action.payload.results)
				.setIn(['products', 'hasBeenLoaded'], true)
				.setIn(['products', 'error'], '');

		case GET_PRODUCTS_FAILED:
			return state
				.setIn(['products', 'isLoading'], false)
				.setIn(['products', 'hasBeenLoaded'], true)
				.setIn(['products', 'error'], action.payload);

		default:
			return state;
	}
};
