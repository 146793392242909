import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import Container from '@mui/material/Container';
import Product from './partials/product';

import { productsActions } from '../../containers/Products';

const styles = (theme) => ({
	pageContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		flexBasis: '100%',
		width: '100%',
		alignItems: 'center',
		color: 'white',
		flexWrap: 'wrap',
		'& div': {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			marginRight: '25px',
			marginTop: '25px',
			marginBottom: '25px'
		},
		background:
			'linear-gradient(114deg, rgba(221,81,57,1) 0%, rgba(135,11,35,1) 100%)'
	}
});

// const productList = [
// 	{
// 		title: 'The Standard',
// 		description: 'This is the Standard description',
// 		images: [],
// 		price: 60.0
// 	},
// 	{
// 		title: 'The Professional',
// 		description: 'This is the full professional set description',
// 		images: [],
// 		price: 90.0
// 	}
// ];

const Products = (props) => {
	const {
		classes,
		// error,
		getProducts,
		hasBeenLoaded,
		isLoading,
		products
	} = props;
	const { pageContainer } = classes;

	useEffect(() => {
		if (!hasBeenLoaded && !isLoading) {
			getProducts();
		}
	}, [hasBeenLoaded, isLoading, getProducts]);

	return (
		<Container className={pageContainer} maxWidth={false}>
			{!isLoading && !hasBeenLoaded && <p>Products not loaded</p>}
			{isLoading && !hasBeenLoaded && <p>Loading Products</p>}
			{!isLoading &&
				hasBeenLoaded &&
				products.map((product, index) => (
					<Product
						key={index}
						description={product.description}
						images={product.images}
						price={product.price}
						title={product.title}
						id={product.id}
					/>
				))}
		</Container>
	);
};

Products.propTypes = {
	classes: PropTypes.object,
	getProducts: PropTypes.func,
	error: PropTypes.string,
	hasBeenLoaded: PropTypes.bool,
	isLoading: PropTypes.bool,
	products: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		isLoading: state.productsReducer.get('products').get('isLoading'),
		hasBeenLoaded: state.productsReducer.get('products').get('hasBeenLoaded'),
		products: state.productsReducer.get('products').get('products'),
		error: state.productsReducer.get('products').get('error')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getProducts: () => {
			dispatch(productsActions.getProducts());
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(Products));
