import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { cartActions } from '../../../containers/Cart';

const styles = (theme) => ({
	cardMediaStyle: {
		backgroundColor: 'blue'
	},
	root: {
		display: 'flex',
		flexDirection: 'row'
	}
});

const Product = (props) => {
	const { addToCart, classes, description, id, images, price, title } = props;
	const { cardMediaStyle, root } = classes;

	const handleAddToCart = () => {
		const product = {
			id,
			title,
			description,
			price,
			images
		};

		addToCart(product);
		// Navigate to Cart
	};

	return (
		<Card className={root} sx={{ maxWidth: 345 }}>
			<CardMedia
				className={cardMediaStyle}
				component="img"
				height="140"
				image={images[0]}
				alt={title}
			/>
			<CardContent>
				<Typography gutterBottom variant="h5" component="div">
					{title}
				</Typography>
				<Typography variant="body2" color="text.secondary">
					{description}
				</Typography>
			</CardContent>
			<CardActions>
				<Typography variant="body2" color="text.secondary">
					{price}
				</Typography>
				<Button size="small" onClick={handleAddToCart}>
					Add To Cart
				</Button>
			</CardActions>
		</Card>
	);
};

Product.propTypes = {
	addToCart: PropTypes.func,
	classes: PropTypes.object,
	description: PropTypes.string,
	id: PropTypes.string,
	images: PropTypes.array,
	price: PropTypes.string,
	t: PropTypes.func,
	title: PropTypes.string
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		addToCart: (payload) => {
			dispatch(cartActions.addToCart(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(Product));
