import { combineEpics } from 'redux-observable';
import { combineReducers } from 'redux';
import { catchError } from 'rxjs/operators';
import { cartReducer } from '../containers/Cart';
import { notificationReducer } from '../containers/Notifications';
import { orderEpics, orderReducer } from '../containers/Orders';
import { productsEpics, productsReducer } from '../containers/Products';
import { userEpics, userReducer } from '../containers/User';

const epics = combineEpics(...userEpics, ...orderEpics, ...productsEpics);

const rootReducer = combineReducers({
	cartReducer,
	notificationReducer,
	orderReducer,
	productsReducer,
	userReducer
});

const rootEpic = (action$, store$, dependencies) =>
	epics(action$, store$, dependencies).pipe(
		catchError((error, source) => {
			console.error('Root Epic Error Catcher', error);
			return source;
		})
	);

export { rootEpic, rootReducer };
