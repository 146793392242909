import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import config from '../../config.json';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import CheckoutForm from './CheckoutForm';
import { orderActions } from '../../containers/Orders';
// import './Order.css';

const styles = (theme) => ({
	root: {}
});

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(config.stripe.key);

const OrderForm = (props) => {
	const {
		classes,
		createPaymentIntent,
		error,
		hasBeenLoaded,
		isLoading,
		payment,
		paymentIntent
	} = props;
	const { root } = classes;

	const [clientSecret, setClientSecret] = useState('');

	useEffect(() => {
		// Create PaymentIntent as soon as the page loads
		// fetch('http://localhost:4000/api/v1/payments/create-payment-intent', {
		// 	method: 'POST',
		// 	headers: { 'Content-Type': 'application/json' },
		// 	body: JSON.stringify({ items: [{ id: 'xl-tshirt' }] })
		// })
		// 	.then((res) => res.json())
		// 	.then((data) => setClientSecret(data.clientSecret));

		console.log('ORDER FORM REFRESH', {
			error,
			hasBeenLoaded,
			isLoading,
			payment,
			clientSecret
		});

		if (!hasBeenLoaded && !isLoading) {
			createPaymentIntent(JSON.stringify(paymentIntent));
		}

		if (!clientSecret && hasBeenLoaded && !error) {
			setClientSecret(payment.clientSecret);
		}
	}, [payment, clientSecret]);

	const appearance = {
		theme: 'stripe'
	};
	const options = {
		clientSecret,
		appearance
	};

	return (
		<div className={root}>
			{clientSecret && (
				<Elements options={options} stripe={stripePromise}>
					<CheckoutForm />
				</Elements>
			)}
		</div>
	);
};

OrderForm.propTypes = {
	classes: PropTypes.object,
	createPaymentIntent: PropTypes.func,
	error: PropTypes.string,
	hasBeenLoaded: PropTypes.bool,
	isLoading: PropTypes.bool,
	payment: PropTypes.object,
	paymentIntent: PropTypes.object,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		isLoading: state.orderReducer.get('paymentIntent').get('isLoading'),
		hasBeenLoaded: state.orderReducer.get('paymentIntent').get('hasBeenLoaded'),
		payment: state.orderReducer.get('paymentIntent').get('payment'),
		error: state.orderReducer.get('paymentIntent').get('error')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		createPaymentIntent: (payload) => {
			dispatch(orderActions.createPaymentIntent(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(OrderForm));
