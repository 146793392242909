import React, { useEffect, useState } from 'react';
import {
	PaymentElement,
	useStripe,
	useElements
} from '@stripe/react-stripe-js';
import config from '../../config.json';

export default function CheckoutForm() {
	const stripe = useStripe();
	const elements = useElements();

	const [message, setMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (!stripe) {
			return;
		}

		const clientSecret = new URLSearchParams(window.location.search).get(
			'payment_intent_client_secret'
		);

		if (!clientSecret) {
			return;
		}

		stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
			switch (paymentIntent.status) {
				case 'succeeded':
					setMessage('Payment succeeded!');
					// TODO: this should actually call out to API (Should it? we already returned the payment intent)
					break;
				case 'processing':
					setMessage('Your payment is processing.');
					break;
				case 'requires_payment_method':
					setMessage('Your payment was not successful, please try again.');
					break;
				default:
					setMessage('Something went wrong.');
					break;
			}
		});
	}, [stripe]);

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		setIsLoading(true);

		const REDIRECT_URL = config.stripe.redirect[process.env.REACT_APP_STAGE];
		console.log('REDIRECT', {
			REDIRECT_URL,
			config,
			env: process.env.REACT_APP_STAGE
		});

		const { error } = await stripe.confirmPayment({
			elements,
			confirmParams: {
				// Make sure to change this to your payment completion page
				return_url: config.stripe.redirect[process.env.REACT_APP_STAGE]
			}
		});

		// This point will only be reached if there is an immediate error when
		// confirming the payment. Otherwise, your customer will be redirected to
		// your `return_url`. For some payment methods like iDEAL, your customer will
		// be redirected to an intermediate site first to authorize the payment, then
		// redirected to the `return_url`.
		if (error.type === 'card_error' || error.type === 'validation_error') {
			setMessage(error.message);
		} else {
			setMessage('An unexpected error occurred.');
		}

		setIsLoading(false);
	};

	return (
		<form id="payment-form" onSubmit={handleSubmit}>
			<PaymentElement id="payment-element" />
			<button disabled={isLoading || !stripe || !elements} id="submit">
				<span id="button-text">
					{isLoading ? <div className="spinner" id="spinner"></div> : 'Pay now'}
				</span>
			</button>
			{/* Show any error or success messages */}
			{message && <div id="payment-message">{message}</div>}
		</form>
	);
}

// import React from 'react';
// import { withStyles } from '@mui/styles';
// import PropTypes from 'prop-types';
// import Stripe from 'stripe';
// // import {
// // 	useStripe,
// // 	useElements,
// // 	PaymentElement
// // } from '@stripe/react-stripe-js';

// const stripe = Stripe(
// 	'pk_test_51LBpBrILiacwg2bnuckU2FsUV35IjI7XbqqrUMueRZ2vELc3ddd4TjM9k1EQx7dJ8Mxvki8cCyHS53y1ah9qVj6n00oLnsiHu4'
// );

// const styles = (theme) => ({});

// const CheckoutForm = () => {
// 	// const stripe = useStripe();
// 	// const elements = useElements();

// 	const handleSubmit = async (event) => {
// 		// We don't want to let default form submission happen here,
// 		// which would refresh the page.
// 		// event.preventDefault();
// 		// if (!stripe || !elements) {
// 		// 	// Stripe.js has not yet loaded.
// 		// 	// Make sure to disable form submission until Stripe.js has loaded.
// 		// 	return;
// 		// }
// 		// const result = await stripe.confirmPayment({
// 		// 	// `Elements` instance that was used to create the Payment Element
// 		// 	elements,
// 		// 	confirmParams: {
// 		// 		return_url: 'https://example.com/order/123/complete'
// 		// 	}
// 		// });
// 		// if (result.error) {
// 		// 	// Show error to your customer (for example, payment details incomplete)
// 		// 	console.log(result.error.message);
// 		// } else {
// 		// 	// Your customer will be redirected to your `return_url`. For some payment
// 		// 	// methods like iDEAL, your customer will be redirected to an intermediate
// 		// 	// site first to authorize the payment, then redirected to the `return_url`.
// 		// }
// 	};

// 	return (
// 		<form onSubmit={handleSubmit}>
// 			{/* <PaymentElement /> */}
// 			<button disabled={!stripe}>Submit</button>
// 		</form>
// 	);
// };

// CheckoutForm.propTypes = {
// 	classes: PropTypes.object,
// 	t: PropTypes.func
// };

// export default withStyles(styles)(CheckoutForm);
