import { fromJS } from 'immutable';
import { ADD_TO_CART, REMOVE_FROM_CART, CLEAR_CART } from './actionTypes';

export const initialState = fromJS({
	cart: []
});

export const cartReducer = (state = initialState, action) => {
	switch (action.type) {
		case ADD_TO_CART:
			/* eslint-disable */
			const currentCart = state.get('cart');
			const cartCopy = [...currentCart];

			cartCopy.push(action.payload);

			return state.setIn(['cart'], cartCopy);
		case REMOVE_FROM_CART:
			// TODO: Remove item at INDEX
			return state
				.setIn(['paymentIntent', 'isLoading'], false)
				.setIn(['paymentIntent', 'payment'], action.payload)
				.setIn(['paymentIntent', 'hasBeenLoaded'], true)
				.setIn(['paymentIntent', 'error'], '');

		case CLEAR_CART:
			return state.setIn(['cart'], []);

		default:
			return state;
	}
};
