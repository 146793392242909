import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
// import { withTranslation } from 'react-i18next';
import Container from '@mui/material/Container';
import Badge from '@mui/material/Badge';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

const styles = (theme) => ({
	cartStyle: {
		height: '40px',
		width: '100px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		cursor: 'pointer'
	},
	logoStyle: {
		// backgroundColor: 'blue',
		height: '40px',
		width: '100px',
		cursor: 'pointer'
	},
	navigationStyle: {
		display: 'flex',
		width: '50vw'
		// justifyContent: 'space-evenly'
	},
	root: {
		backgroundColor: '#fafafa',
		display: 'flex',
		// height: '1rem',
		// height: '80px',
		justifyContent: 'space-between',
		// padding: '3rem 1.5rem 6rem',
		width: '100%',
		'&p': {
			fontSize: '12px',
			textAlign: 'center'
		}
	}
});

const Header = (props) => {
	const { cartItemCount, classes } = props;
	const { cartStyle, logoStyle, navigationStyle, root } = classes;

	const history = useHistory();

	const _handleHomeRedirect = () => {
		history.push('/');
	};
	const _handleProductRedirect = () => {
		history.push('/products');
	};
	const _handleContactRedirect = () => {
		history.push('/contact-us');
	};
	const _handleCartRedirect = () => {
		history.push('/cart');
	};

	return (
		<Container maxWidth={false} className={root}>
			<div className={navigationStyle}>
				<div onClick={_handleHomeRedirect} className={logoStyle}></div>
				<div className={navigationStyle}>
					<Button variant="text" onClick={_handleProductRedirect}>
						Products
					</Button>
					<Button variant="text" onClick={_handleContactRedirect}>
						Contact Us
					</Button>
				</div>
			</div>
			<div className={cartStyle}>
				<Badge
					badgeContent={cartItemCount || null}
					color="primary"
					onClick={_handleCartRedirect}
				>
					<ShoppingCartIcon color="action" />
				</Badge>
			</div>
		</Container>
	);
};

Header.propTypes = {
	cartItemCount: PropTypes.number,
	classes: PropTypes.object,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		cartItemCount: state.cartReducer.get('cart').length
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		// toggleShoppingCart: () => {
		// dispatch(cartActions.toggleCartModal());
		// }
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(Header));
