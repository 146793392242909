import {
	GET_PRODUCTS,
	GET_PRODUCTS_COMPLETED,
	GET_PRODUCTS_FAILED
} from './actionTypes';

export const getProducts = (payload) => ({
	type: GET_PRODUCTS,
	payload
});
export const getProductsCompleted = (payload) => ({
	type: GET_PRODUCTS_COMPLETED,
	payload
});

export const getProductsFailed = (payload) => ({
	type: GET_PRODUCTS_FAILED,
	payload
});
