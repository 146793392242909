import { fromJS } from 'immutable';
import {
	CREATE_PAYMENT_INTENT,
	CREATE_PAYMENT_INTENT_COMPLETED,
	CREATE_PAYMENT_INTENT_FAILED
} from './actionTypes';

export const initialState = fromJS({
	paymentIntent: {
		isLoading: false,
		hasBeenLoaded: false,
		payment: null,
		error: null
	}
});

export const orderReducer = (state = initialState, action) => {
	switch (action.type) {
		case CREATE_PAYMENT_INTENT:
			return state.setIn(['paymentIntent', 'isLoading'], true);

		case CREATE_PAYMENT_INTENT_COMPLETED:
			return state
				.setIn(['paymentIntent', 'isLoading'], false)
				.setIn(['paymentIntent', 'payment'], action.payload)
				.setIn(['paymentIntent', 'hasBeenLoaded'], true)
				.setIn(['paymentIntent', 'error'], '');

		case CREATE_PAYMENT_INTENT_FAILED:
			return state
				.setIn(['paymentIntent', 'isLoading'], false)
				.setIn(['paymentIntent', 'hasBeenLoaded'], true)
				.setIn(['paymentIntent', 'error'], action.payload);

		default:
			return state;
	}
};
